.category-item img {
    max-width: 100%;
    border-radius: 10px;
     max-height: 240px;
}
.mobile-product{
    width: 48px;
    height: 48px;
    background-color: #f6f6f6;
    border-radius: 12px;
    overflow: hidden;
}
.sub-p-mobile{
    margin-top: 4px;
    color: black;
    font-weight: bold;
}
.category-item p {
    text-align: center;
    margin: 10px 0px;
    font-size: 16px;
    color: #000;
}

.category-item a {
    text-decoration: none;
}

.categories-container {
    margin-bottom: 30px;
}

.category-item {
    text-align: center;
}

.subcat-item-container img {
    max-width: 100%;
    border-radius: 5px;
}

.subcat-item-container {
    padding: 10px 20px;
    border: 1px solid #ddd;
}
.subcat-item-container-mobile{
    margin: 12px 0px 0px 0px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
}
.subcat-item-container-mobile a{
    text-decoration: none;
}
.subcategories-list {
    border: 1px solid #ddd;
    padding: 10px 0px;
}
.subcategories-list-mobile {
    box-shadow: 1px 0px 3px -3px rgba(0,0,0,0.75);
    border-right: 1px solid #eee;
    display: flex;
    flex-direction: column;
    align-items: center;
    float: left;
    min-width: 84px;
    max-width: 84px;
    /* overflow-y: scroll; */
    box-sizing: border-box;
}

.subcat-item-container a {
    color: #000;
    text-decoration: none;
}

.sub-p-container {
   text-align:center;
}

p.sub-p {
    margin: 0px;
    vertical-align: middle;
    text-align: center;
}

.subcat-item-container:hover {
    background: #eafdeb;
}

.posthead {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.posthead p{
    margin-bottom: 0px;
}
.profile-pic-div { 
    flex: 1;
}
.profile-pic{
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.profile-title {
    flex: 12;
    margin-left: 8px;
}

p.profile-actions {
    flex: 1;
}
.post-img{
    max-width: 100%;
    max-height: calc(100vh - 120px);
}

.post-symbol-icons {
    font-size: 30px;
    margin-right: 10px;
}

.post-footer {
    margin: 10px 0px;
    display: flex;
}
.symbols {
    flex: 1;
}
.more-icon {
    font-size: 30px;
}

.posting-container {
    padding: 0px 15px;
}

.post-item {
    margin-bottom: 40px;
}
.post-image {
    text-align: center;
}
.viewphoto-popup .modal-body {
    padding: 0px;
}
.post-modal-view {
    display: flex;
}
img.post-modal-img {
    max-width: 100%;
}
.pm-img-container {
    flex: 1;
}
.pm-content-container {
    flex: 1;
}
.homescreen .post-inner-container {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}
.homescreen .post-image {
    border: 1px solid #ddd;
}

.search-container .profile-pic {
    width: 150px;
    height: 150px;
}
.search-container .profile-title{
    margin-left:20px;
}

.search-container .profile-title p {
    font-size: 16px;
    margin-bottom: 10px;
}
.search-container .profile-title p a{
    font-size: 26px;
    font-weight: 600;
}

img.post-dp {
    margin-right: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.pdpop-header {
    padding: 10px;
    display: flex;
}
.pdpop-ls {
    flex: 1;
}
.pdpop-ls-container {
    display: flex;
}
.pdpop-titles {
    flex: 9;
}
.pdpop-titles p {
    margin-bottom: 0px;
    font-size: 16px;
    text-transform: capitalize;
    font-weight: 500;
}
.pdpop-titles p:last-child {
    font-size: 12px;
    font-weight: 400;
}
.pdpop-hr {
    margin: 0;
}
.shared-post {
    max-width: 800px;
    margin: auto;
    padding: 30px 0px;
}
.pm-content-container {
    position: relative;
}

.post-pop-footer-container-desktop {
    position: absolute;
    bottom: 10px;
    width: 100%;
    border-top: 1px solid #ddd;
        padding: 10px;
}
.navigation-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-prev {
    position: absolute;
    top: 50%;
    left: -20px;
    font-size: 40px;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
}

.nav-next {
    position: absolute;
    top: 50%;
    right: -20px;
    font-size: 40px;
    background: #fff;
    border-radius: 50%;
    border: 1px solid #ddd;
    cursor: pointer;
}
@media screen and (max-width: 767.98px) {
    .hide-mobile {
        display: none;
    }
    .post-modal-view {
        flex-direction: column-reverse;
    }
    img.post-modal-img {
        max-height: none;
    }
    
    p.posttitle {
        margin: 0;
        flex: 5;
        font-weight: 700;
        font-size: 15px;
    }
    .model-header-popup {
        display: flex;
        align-items: center;
    }
    .model-header-wid {
        width: 100%;
    }
    .model-popup-back {
        flex: 4;
    }
    .pm-img-container {
        text-align: center;
    }
    .mobile-pop-foot  .shortlist {
        flex: 1;
    }
}