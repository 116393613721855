.order-header-main {
    border: 1px solid #DDD;
}

.orders-inProgress {
    display: flex;
    gap: 30px;
    align-items: center;
    cursor: pointer;
}

.orders-inProgress p {
    margin-top: none;
    margin-bottom: none;
}

.Manage-Orders {
    display: flex;
    align-items: center;
    justify-content: start;
}

.Manage-Orders h6 {
    color: #5C5C5C;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.price-border {
    border-bottom: 1px solid #DDD;
}

.orders-new {
    border-right: 1px solid #DDD;
    display: flex;
    align-items: center;
    justify-content: center;
}

.orders-inprogres-main {
    text-align: center;
    border-right: 1px solid #DDD;
}

.orders-inprogres-sub {
    padding: 10px;
    border-bottom: 1px solid #DDD;
    cursor: pointer;
}

.orders-inprogres-sub h6 {
    color: #5C5C5C;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.search-input {
    display: flex;
    align-items: center;
    border: 1px solid #DDD;
    margin-top: 10px;
    text-align: center;
    padding: 5px
}
.input-with-icon {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
    margin-top:10px;
  }  
  .icon {
    margin-right: 8px;
  }
.input-control {
    border: none;
    outline: none;
    width: 100%;
}

.input-control:focus {
    border: none;

}

.order-data {
    margin-top: 12px;
    height: calc(100vh - 50px);
    overflow-y: auto;
    border: 1px solid #ddd;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.order-act {
    height: calc(100vh - 50px);
    overflow-y: auto;
    padding-left: 0;
}

.ordrs-bottom:hover {
    background-color: #FFD2F4;
    cursor: pointer;
}

.ordrs-bottom {
    border: 1px solid #DDD;
    padding: 10px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.ordrs-bottom.active {
    background-color: #FFD2F4;
    cursor: pointer;
    border: 1px solid #DDD;
    padding: 10px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.ordrs-total {
    border-top: 1px solid #ddd;
    padding: 18px;
}

.ordrs-tota-button {
    display: flex;
    justify-content: end;
    padding: 5px;
}

.oreder-precessed p {
    color: #A72B89;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.oreder-precessed {
    display: flex;
    gap: 30px;
}

.order-actions {
    border-right: 1px solid #DDD;
}

.order-header header {
    display: none;
}

.order-header {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.border-order {
    padding-left: 12px;
}

.itm-table {
    display: flex;
    align-items: center;
    gap: 14px;
    padding-top: 14px;
    padding-bottom: 14px;
}

.table-itm-img {
    width: 56px;
    height: 58px;

}

.order-money-items {
    color: #6F7588;
    font-size: 16px;
    margin-top: 8px;
    display: flex;
    gap: 22px;
    align-items: center;
}

.activeCalss-order {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #A72B89;
    border-bottom: 5px solid #A72B89;
    box-sizing: border-box;
    padding: 7px;
    display: flex;
    align-items: center;
    gap: 4px;
    text-align: center;
}

.Class-order {
    padding: 7px;
    padding: 7px;
    display: flex;
    align-items: center;
    color: #5C5C5C;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    gap: 4px
}

.new-Class-order {
    padding: 7px;
    display: flex;
    align-items: center;
    gap: 4px;
    color: #5C5C5C;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.btn-pink {
    background-color: #A72B89;
    color: white;
}

.btn-pink:hover {
    border: 1px solid #A72B89;
}

.btn-black:hover {
    border: 1px solid #5C5C5C;
}

.btn-black {
    background-color: #5C5C5C;
    color: white;
    border: 2px solid #5C5C5C;
    margin-left: 10px;
}

.butto-order {
    display: flex;
    align-items: center;
    gap: 20px;
}

.button-border {
    border-right: 1px solid #DDD;
}

.icons-ordr-size {
    width: 30px;
    height: 30px;
    color: #5C5C5C;
    border: 1px solid #5C5C5C;
    padding: 2px;
    margin-right: 20px;
    cursor: pointer;
}

.money-data {
    display: flex;
    justify-content: end;
    margin-top: 31px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
}

.details-money {
    display: flex;
    justify-content: space-between;
    width: 250px;
    font-size: 16px;
    line-height: 20px;
}

.store-details-roder {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.details-money-total {
    display: flex;
    justify-content: space-between;
    width: 250px;
    font-size: 20px;
    font-weight: 600;
    padding-top: 9px;
    padding-bottom: 9px;
}

.no-orders {
    display: flex;
    align-items: center;
    height: 212px;
    background-size: 100% 100%;
    background-image: url('https://merchantdash.dunzo.in/static/media/zero-state.4bbeb9fd.svg');
}

.para-table-nodata {
    color: #9FA3AF;
    font-size: 32px;
}

.button-text-order {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
}

.button-orders-image {
    background-color: white;
    padding: 8px 16px 8px 16px;
    border-color: #6F7588;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    line-height: 1.75;
    border-radius: 5px;
}

.button-orders-image:hover {
    background-color: #6f75881a;
}

.image-name-order {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 3px;
}

.zero-padding {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.success-image {
    width: 22px;
    height: 20.93px;
}

.length-data {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    color: white;
    flex-shrink: 0;
    background: #A72B89;
    display: flex;
    align-items: center;
    justify-content: center;
}

.lenght-Inactive {
    border-radius: 3px;
    background: #A2A2A2;
    width: 20px;
    height: 20px;
    color: white;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}