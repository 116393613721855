.flex-between-align {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.back-buttons {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #212529;
    padding: 36px 10px 25px 35px;
}

.Application-tabs {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    border-bottom: 1px solid #EDD3FF;
    padding: 10px 40px;
}

.application-container {
    background: #F0F5F8;
    padding: 35px;
    font-family: "poppins";
}

.Application-feedback {
    background: #EDD3FF;
    padding: 10px 30px;
    border-radius: 0 0 10px 10px;
}

.Application-feedback p {
    margin: 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
}

.feedback-content {
    margin: 0;
    font-weight: 400;
    font-size: 15px;
    line-height: 22.5px;
    color: #555555;
    height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.application-content {
    margin-bottom: 20px;
}

.application-details {
    border-radius: 10px 10px 0px 0px;
    background: #FFFFFF;
    border: 0.5px solid #555555;
    display: flex;
    padding: 25px 30px;
    justify-content: space-between;
}

.applicant-details p {
    font-size: 18px;
    font-weight: 500;
    line-height: 29px;
    margin: 4px 0;
}

.application-select {
    background: #6a0dad;
    border-radius: 16px;
    color: #fff;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: .02em;
    line-height: 27px;
    padding: 0 0 0 15px;
}

.view-btn,
.message-btn {
    text-align: center;
    font-size: 18px;
    width: fit-content;
    line-height: 27px;
    letter-spacing: 0.02em;
    padding: 5px 22px;
    border-radius: 18px;

}

.view-btn {
    background: #6A0DAD;
    color: #fff;
    margin-bottom: 8px;
    font-weight: 500;
}

.message-btn {
    border: 1px solid #EDD3FF;
    color: #212529;
    font-weight: 400;
    display: flex;
    align-items: center;
    gap: 10px;
    height: 31px;
}

.view-btn:hover,
.message-btn:hover {
    opacity: 0.8;
}

.applicant-info {
    display: flex;
    align-items: center;
    margin-right: 70px;
    gap: 20px;
}

.application-list {
    margin-top: 50px;
}

.applicant-actions {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.statuses {
    font-weight: 500;
    font-size: 24px;
    line-height: 38.76px;
    color: #3CB371;
    text-transform: capitalize;
    margin-bottom: 5px;
}

.applicant-image {
    width: 82px;
    height: 82px;
    border-radius: 50%;
}

.Application-tabs {
    border-bottom: 1px solid #edd3ff;
    display: flex;
    justify-content: space-between;
    padding: 10px 40px;
}

.chat-type {
    background: #6a0dad;
    border-radius: 13.5px;
    color: #fff;
}

.chat-deselect,
.chat-type {
    font-family: poppins;
    font-size: 18px;
    font-weight: 400;
    height: fit-content;
    padding: 0 20px;
    text-align: center;
    width: fit-content;
}

.chat-deselect {
    border: 1px solid #edd3ff;
    border-radius: 13.5px;
    color: #555;
}

p.height {
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
}

.application-loc {
    display: flex;
}

.applicant-meta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 70px;
}

.Application-modal {
    align-items: center;
    border-bottom: .5px dashed #bebebe;
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    padding-bottom: 15px;
}

button.btn.btn-primary.btn-block.radius {
    border-radius: 32px;
    color: #fff;
    font-size: 25px;
    font-weight: 600;
    height: 50px;
}

button.btn.btn-primary.btn-block.radius:hover {
    border-radius: 32px;
    color: #6a0dad;
    font-size: 25px;
    font-weight: 600;
    height: 50px;
}