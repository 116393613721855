.flri {
    text-align: right;
}
.display-bio h6 {
    border-bottom: 3px solid #ed0d81;
    width: fit-content;
    padding: 6px 0px;
}
.display-bio .form-group label {
    text-transform: capitalize;
}
.display-bio input, .display-bio select,.display-bio .form-control {
    font-weight: 600;
}
.project-info{
    display: flex;
}
.project-titles{
    flex: 1;
}