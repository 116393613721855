.icon-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    cursor: pointer;
}

.horizontal_line {
    color: #5C5C5C;
    margin-top: 30px;
    margin-bottom: 30px;
}

.radio-opoto {
    width: 20px;
    height: 20px;
    color: #A72B89;
    margin-right: 12px;
}

.checkbox-opto {
    width: 16px;
    height: 16px;
    color: #A72B89;
}

.rectangle {
    border: 1px solid #DDD;
    background: white;
    color: black;
    width: 90px;
    height: 37px;
}

.square {
    text-align: center;
    border: 1px solid #DDD;
    border-radius: 4px;
    background: white;
    color: black;
    width: 120px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.square.selected {
    border: 1px solid #A72B89;
    border-radius: 4px;
    color: #A72B89;
}

.AddModality-Manage {
    padding: 10px;
    background: #FFFFFF;
    border-radius: 4px;
}

.image-container {
    position: relative;
    display: inline-block;
}

.cross-icon {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    /* Add more styling as needed */
}

.manageMain {
    margin-top: 37px;
    background: #FFFFFF;
    border-width: 1px 1px 0px 1px;
    border-style: solid;
    border-color: #CACACA;
    padding-top: 17px;
    padding-bottom: 16px;
}

.image-item {
    width: 100%;
    height: 100%;
    padding: 15px;
}

.text-block-border {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    border-bottom: 1px solid;
}

.text-block-end {
    border: 1px solid;
    margin-top: 10px;
}

.backgroun-upload {
    border-radius: 5px;
    border: 1px dashed #A72B89;
    padding: 42px;
    background: #FFF3FC;
}

.upload-multiple {
    height: 118px;
    width: 118px;
    border-radius: 10px;
    background: #FFE4F9;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload-width {
    width: 24px;
    height: 24px;
}

.custom-modal-width {
    max-width: 1200px;
    /* Adjust the width as needed */
    width: 100%;
}

.drag-style {
    font-family: "inter";
    color: #000;
    opacity: 0.5;
}

.main-container-folder {
    flex: 1 1;
    background-color: #FFE4F9;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 5px;
    border: 2px solid #000;
    border-style: dashed;
}

.upload-plus {
    width: 78px;
    height: 78px;
    border-radius: 10px;
    border: 0.5px dashed #A72B89;
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-container {
    display: flex;
    gap: 30px
}

.image-grid {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 30px;
}

.flex-item-image {
    width: 118px;
    height: 118px;
    border-radius: 10px;
    border: 0.5px solid #C7C7C7;
    display: flex;
    padding: 5px;
    justify-content: center;
}

.custom-toast {
    position: absolute;
    bottom: 20px;
    z-index: 1000;
}

.image-taost {
    height: 24px;
    width: 24px;
}

.style-taost-para {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
}

.style-taost-p {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 131px;
}

.grid-item {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
}

.style-icon-activity {
    width: 18px;
    height: 18px;
}

.display-head {
    border-bottom: 1px solid #DDD;
    padding: 15px
}

.flexitem {
    display: flex;
    border: 1px solid #DDD;
}

.input-control {
    width: 100%;
    /* border: 1px solid #DDD; */
    border-radius: 4px;
    outline: none;
    border: none;
}

.margin-inventry {
    margin-bottom: 100px;
}

.input-control:focus {
    outline: none;
    /* Remove the border on focus */
}

.dashboard-subtitle-custom {
    margin-bottom: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #000;
    padding-top: 8px;
}

.btn-add-module {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding-top: 92px;
    border: 1px solid #5C5C5C;
    padding-bottom: 92px;
}

.module-border {
    border: 1px solid #5C5C5C;
    padding: 11px;
    height: 219px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cursor-pointer {
    cursor: pointer;
}

.manageshow {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    color: #5C5C5C;

}

.managenumbermain {
    width: 63px;
    height: 23px;
    border: 0.5px solid #CACACA;
    border-radius: 1px;
    margin-left: 10px;
    margin-right: 10px;
}

.managenumber {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */


    color: #5C5C5C;

}

.manageimage {
    max-height: 18px;
    margin-right: 16px;
    cursor: pointer;
}

.tableheader {
    max-width: 26px;
    max-height: 16px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #1D273B;
    background: #F0F8FF;
    border: 1px solid #CACACA;
}

.tablemargin {
    padding-top: 10px;
    padding-bottom: 10px;
}

.managePages {
    background: #F0F8FF;
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: #CACACA;
    padding-top: 21px;
    padding-bottom: 21px;
}

.managetabledata {
    background: #FFFFFF;
    border: 1px solid #CACACA;
    height: 56px;
}

.showingEntries {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */


    color: #5C5C5C;
    height: 17px;
}

.managebutton {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    margin: 34px;
    color: #CECECE;

}

.managebuttonnumber {
    width: 7px;
    height: 17px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    margin-right: 16px;

    color: #5C5C5C;
}

.managefilters {
    width: 39px;
    height: 12px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;


    color: #1D273B;
}

.managefilters1 {
    width: 39px;
    height: 12px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: #00769B;
    margin-left: 12px;
}

.modalinput {
    width: 136px;
    height: 49px;
    border: 1px solid #8F8F8F;
    border-radius: 8px;
    margin-bottom: 16px;
    padding-right: 16px;
}

.location-icon {
    width: 10px;
    height: 10px;
    color: #5C5C5C;
}

.location-text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    margin-left: 12px;
    color: #5C5C5C;

}

.location-text:hover {
    color: #00769B;
}

.tick-icon {
    margin-left: 4px;
    width: 10px;
    height: 10px;
}

.copyright-text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height */

    margin-left: 14px;
    color: #057697;
}

.users-modal {
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    align-items: center;
    margin-top: 20px;
    background-color: #00769B;
}

.dashboard-2 {
    color: #057697;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}

.dashboard-1 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    padding-top: 8px;
    cursor: pointer;
}

.addimage {
    width: 14px;
    height: 14px;
}

.deletefields {
    width: 14px;
    height: 2px;
}

.Client-main {
    margin-top: 30px;
}

.client-sub-main {
    margin-top: 60px;
}

.upload-Download {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #00769B;
    cursor: pointer;
}

.f-copy {
    color: #057697;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    margin-top: 112px;
}

.uploadBox {
    border: 1px dashed #00769B;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    color: #00769B;
    padding-top: 100px;
    padding-bottom: 100px;
    text-align: center;
    cursor: pointer;
}

.my-custom-scrollbar {
    position: relative;
    height: 500px;
    overflow: auto;
}

.table-wrapper-scroll-y {
    display: block;
}

.uploadBox>input {
    display: none;
}

.radioupload>input {
    display: none;
}

.radio-tags {
    margin-right: 20px
}

.upload-head {
    margin-top: 36px;
}

.radio-tags>p.radio-ptag {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 20px;
}

.radio-formcheck {
    margin-top: 48px;
}

.rdt_TableCol {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
}

.search-form {
    padding: 20px 0px;
}

.search-dropdowns p {
    margin-bottom: 0px;
    padding: 2px 15px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
}

.search-dropdowns p:hover {
    background: #f4fafe;
    color: #00769B;
}

.search-dropdowns {
    border: 1px solid #ddd;
    border-top: none;
}

.scrollmenu {
    overflow: auto;
    white-space: nowrap;
    overflow-x: auto;
}

.ClientTariff-filter {
    margin-right: 20px;
}

.accordion-container {
    margin-top: 20px;
}

.accordion-body .container-form {
    padding-top: 0px;
}

.TariffModal {
    display: flex;
    justify-content: end;
    margin: 12px
}

.rowsPerpage {
    color: rgba(0, 0, 0, 0.54);
    margin-left: 4px;
    display: flex;
    align-items: center;
    margin-right: 4px;
}

.serPerpage {
    border: none;
    color: inherit;
    background-color: transparent;
    margin-left: 8px;
    margin-right: 8px;
}

.serPerpage:hover {
    border: black;
}

.form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
}

.form-inline label {
    min-width: 200px;
}

.sidebar-child-container {
    max-height: calc(100vh - 50px);
    overflow-y: auto;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment {
    display: block;
    height: 1px;
}

::-webkit-scrollbar-track-piece {
    background-color: #eef7fe;
    -webkit-border-radius: 20px;
}

::-webkit-scrollbar-thumb:vertical {
    height: 50px;
    background-color: #c7e5fd;
    -webkit-border-radius: 3px;
    -webkit-border-radius: 20px;
}

::-webkit-scrollbar-thumb:horizontal {
    height: 50px;
    background-color: #c7e5fd;
    -webkit-border-radius: 3px;
    -webkit-border-radius: 20px;
}

.loader-container {
    text-align: center;
}

.loader-container img {
    width: 200px;
    padding-top: 60px;
}

.upload-Certificates {
    margin-top: 40px;
}

.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.pagination-icon {
    fill: currentColor;
    cursor: pointer;
}

.table-error {
    font-size: 24px;
    text-align: center;
    font-weight: 500;
    padding-top: 60px;
    color: #6c757d;
    text-decoration-line: underline;
}

.clientbill-info {
    background-color: #EEF7FF;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #007698;
}

.tablescroll {
    max-height: calc(100vh - 250px);
    overflow-y: scroll;
    margin-top: 10px;
    margin-bottom: 50px;
}

.clientbill-info p {
    margin-bottom: 4px;
}

.form-control.form-discount {
    height: 30px;
    margin-bottom: 8px;
}

.table>thead {
    text-align: "start";
    background-color: #EEF7FF;
    color: #007698;
}

th {
    font-weight: 700;
    line-height: 24px;
    font-size: 12px;
    text-transform: uppercase;
}

.table>tbody {
    font-size: 13px;
    font-weight: 400;
}

.billingModality {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.action-title {
    font-weight: bold;
    font-size: 8px;
    line-height: 20px;
}

.specialities-main {
    margin-top: 20px;
}

.Specialities-master {
    border: 2px solid #888888;
    border-radius: 4px;
    padding-left: 14px;
    padding-top: 15px;
    padding-bottom: 22px;
    padding-right: 8px;
}

.specialties-mas.inactive {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 23px;
    padding: 8px;
    border: 2px solid #888888;
    border-radius: 4px;
}

.specialties-mas.Acitve {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 2px solid #409D9B;
    border-radius: 4px;
    margin-top: 23px;
    padding: 8px;
    font-weight: bold;
}

.clinicspeciality.acitve {
    color: green;
}

.clinicspeciality.inacitve {
    color: red;
}

.specialties-service {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 23px;
    padding: 8px;
    border: 2px solid #888888;
    border-radius: 4px;
}

.row-check {
    padding: 5px;
    border: 2px solid #007698;
}

.Doctor-main {
    margin-top: 23px;
}

.time-input {
    border-top: none;
    border-left: none;
    border-right: none;
}

.delete {
    border: none;
    border-bottom: none;
}

.table td.Cross {
    border: none;
}

.RxCross {
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
}

.complaints-container {
    position: relative;
    border: 1px solid #ddd;
    padding: 15px;
}

h3.timeline-pastvisits {
    margin-bottom: 0;
    margin-top: 10px;
    padding: 7px;
    font-size: 18px;
    color: #fff;
    font-weight: 700;
    text-align: center;
    background-color: #007698;
}

.invalid-error {
    border: 1px solid var(--bs-danger-text, #ff0000);
    /* Fallback to #ff0000 if variable is not defined */
    border-radius: 4px;
}

.invalid {
    color: var(--bs-danger-text, #ff0000);
}

.input-box>div {
    flex: 1 1;
}

.complaints-dropdown-show {
    position: absolute;
    left: 6%;
    height: 250px;
    /* width: calc(100% - 40px); */
    width: 94%;
    display: block;
    background: #FFFFFF;
    box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    overflow: hidden;
    z-index: 4;
}

.complaints-dropdown-hide {
    display: none;
}

.emr-data-container {
    position: relative;
}

.rbc-addons-dnd.rbc-calendar {
    width: calc(100vw - 300px);
    height: calc(100vh - 50px);
}

.rbc-timeslot-group {
    min-height: 200px !important;
}

/* .rbc-events-container.rbc-event.rbc-addons-dnd-resizable{
   display: grid;
   grid-template-columns: auto auto;
}  */
.rbc-events-container .rbc-event {
    width: 50px !important;
    left: 0px !important;
    height: auto;
}

.emr-dropdown {
    height: 250px;
    display: block;
    background: #FFFFFF;
    box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    overflow: hidden;
    z-index: 4;
}

.emr-list {
    line-height: 25px;
}

.list-category:nth-of-type(1) {
    overflow-y: scroll;
}

p.tb-selector-title {
    margin: 0;
    padding: 5px 10px;
    cursor: pointer;
}

p.tb-selector-title:hover {
    background-color: #ddd;
}



.colitem {
    overflow-y: auto;
    max-height: 240px;
}

.row-item {
    margin-bottom: 10px;
}

.emr-form-icon {
    font-size: 24px;
}

.row-df {
    display: flex;
    align-items: center;
}

.select-div {
    width: 160px;
}


.patient-info {
    display: flex;
}

svg.user-icon {
    font-size: 50px;
}

.end {
    flex: 9;
}

.end button {
    float: right;
}

.rs {
    padding-left: 10px;
}

.set-of-rows {
    margin-bottom: 40px;
}

.hx-vitalFields {
    border: 1px solid #DFE1E2;
}

.vital-input {
    font-size: 14px;
    line-height: 1.429;
    color: #243951;
    border-radius: 4px;
    padding: 5px 12px;
}

.hx-vitalUnit {
    text-align: center;
    width: 50px;
    height: 32px;
    background: #F8F8F8;
    border: 1px solid #DFE1E2;
    border-radius: 0px 4px 4px 0px;
    right: 0;
    line-height: 2.15;
}

.vital-input-bp {
    font-size: 14px;
    line-height: 1.429;
    color: #243951;
    border: none;
    border-radius: 4px;
    padding: 5px 12px;
}

.sidebar-child-emr {
    background: #dbefffc1;
    box-shadow: 2px 0px 1px rgba(0, 0, 0, 0.25);
    width: 187px;
}

.mainbar {
    display: flex;
    margin-top: -12px;
    margin-left: -18px;
}

.sidebar-filters {
    background: #FFFFFF;
    box-shadow: 2px 0px 1px rgba(0, 0, 0, 0.25);
    width: 187px;
    padding: 10px 15px;
    margin-left: 10px;
    height: calc(100vh - 50px);
    overflow-y: auto;
}

.main-code {
    height: calc(100vh - 50px);
    overflow-y: auto;
    overflow-x: auto;
}

.dashboard-content {
    height: calc(100vh - 50px);
    overflow-y: auto;

    /* Hide scrollbar for Webkit-based browsers (Chrome, Safari) */
    scrollbar-width: none;
    /* For Firefox */
    -ms-overflow-style: none;
    /* For Internet Explorer and Edge */
}


.btn-filter {
    width: 100%;
}

.showing-from-date {
    padding: 12px 20px;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    background-color: #E9EAF0;
    margin-top: 10px;
}

.report-id {
    color: #0F1938;
    font-size: 12px;
    font-weight: 500;
}

.table-padding {
    padding-top: 16px;
    padding-bottom: 16px;
}

.order.Delivered {
    padding: 8px 12px;
    font-size: 12px;
    font-weight: 700;
    border-radius: 4px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #00B37A;
    background-color: #E6F8F2;
}

.btn-button-filter {
    margin-top: 10px;
}

.merchant-details {
    margin-top: 16px;
}

.sidebar-filters-reports {
    background: #FFFFFF;
    box-shadow: 2px 0px 1px rgba(0, 0, 0, 0.25);
    width: 320px;
    padding: 10px 15px;
    margin-left: 10px;
    height: 100vh;
    height: calc(100vh - 50px);
    overflow-y: auto;
}

.number-count {
    font-weight: 700;
}

.merchant-money {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    line-height: 30px;
}

.merchant-total-money {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    line-height: 30px;
    color: black;
    font-weight: 500;
}

.merchant-summary {
    color: #6F7588;
    font-size: 14px;
    margin-top: 12px;
}

.marchant-pay {
    font-size: 20px;
    font-weight: 700;
}

.order.cancelled {
    padding: 8px 12px;
    font-size: 12px;
    font-weight: 700;
    border-radius: 4px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #E24C4B;
    background-color: #FDEEED;
}

.sidebar-filters1 {
    /* flex: 1; */
    background: #FFFFFF;
    box-shadow: 2px 0px 1px rgba(0, 0, 0, 0.25);
    width: 187px;
    padding: 10px 15px;
    margin-left: 10px;
    height: 100vh;
}

.main-code {
    flex: 1;
    padding: 10px;
}

.pie-height {
    height: 349px !important;
}

/* calender styles */
.cal-header {
    display: flex;
    justify-content: space-around;
    border: 1px solid #ddd;
    padding: 5px 0px;
    align-items: center;
}

.date-selection {
    display: flex;
}

.calender-main-container {
    border: 1px solid #ddd;
    overflow-x: scroll;
    max-width: calc(100vw - 300px);
}

.cal-item {
    display: flex;
    align-items: center;
    padding: 5px 15px;
}

.cal-con-blocks {
    flex: 9;
}

.cal-con-doctors {
    flex: 1 1;
    height: 90px;
    width: 140px;
    overflow-y: auto;
}

.cal-con-body p {
    margin-bottom: 0px;
}

.cal-con-availability .cal-con-body p {
    font-size: 12px;
}

.calender-main-container {
    padding-top: 10px;
}

.cal-con-availability {
    flex: 1;
}

.calender-time-blocks {
    display: flex;
    justify-content: space-between;
}

.cal-block-itm {
    width: 70px;
    margin: 0;
}

.cal-con-head {
    border-bottom: 1px solid #ddd;
}




.slots-div {
    height: 70px;
    display: grid;
    grid-template-columns: auto auto auto;
}

.slot-item {
    margin: 2px;
}

.btn-slot:hover {
    border: 1px solid #000;
}

.btn-slot {
    border: 1px solid #a39797;
    height: 30px;
    width: 30px;
}

.slot-green {
    background-color: green;
    border: 1px solid #a39797;
    height: 30px;
    width: 30px;
}

.slot-red {
    background-color: red;
    border: 1px solid #a39797;
    height: 30px;
    width: 30px;
}

.btn-grey {
    background-color: gray;
    border: 1px solid #a39797;
    height: 30px;
    width: 30px;
}

.slot-red {
    background-color: red;
    border: 1px solid #a39797;
    height: 30px;
    width: 30px;
}

.visit-type {
    margin-left: 50px;
}

.calender-vertical-layout {
    padding-left: 90px;
}

.calender-doctor {
    display: flex;
    align-content: space-between;
}

.calender-timeslots {
    flex: 1;
}

.cal-ver-itm {
    border-bottom: 1px solid #ddd;
    position: relative;
}

.calender-vertical-item {
    flex: 1;
}

.cal-con-blocks {
    border-top: 1px solid #ddd;
    padding-top: 0px;
}

.cal-con-availability p {
    margin: 0;
}

p.cal-block-itm {
    position: absolute;
    left: -80px;
    top: 30px;

}

img.doctor-dp {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 5px;
}

/* end calender styles */
.search-drop {
    z-index: 5000;
}

.tick-icon {
    color: green;
}

.icon {
    color: black;
}

.back-app {
    color: black;
}

.template {
    display: flex;
    justify-content: end;
    align-items: center;
}

.save-button {
    margin-top: 10px;
}

/* Past Visits */
.timeline {
    background-color: #474e5d;
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
}


.timeline::after {
    content: '';
    position: absolute;
    width: 6px;
    background-color: white;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
}


.past {
    padding: 10px 40px;
    position: relative;
    background-color: inherit;
    width: 50%;
}


.past::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    right: -13px;
    background-color: white;
    border: 4px solid #FF9F55;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
}


.left {
    left: 0;
}


.right {
    left: 50%;
}


.left::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    right: 30px;
    border: medium solid white;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent white;
}


.right::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    left: 30px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
}


.right::after {
    left: -16px;
}


.content {
    background-color: white;
    position: relative;
    border-radius: 6px;
}


@media screen and (max-width: 600px) {

    .timeline::after {
        left: 31px;
    }


    .past {
        width: 100%;
        padding-left: 70px;
        padding-right: 25px;
    }


    .past::before {
        left: 60px;
        border: medium solid white;
        border-width: 10px 10px 10px 0;
        border-color: transparent white transparent transparent;
    }


    .left::after,
    .right::after {
        left: 15px;
    }


    .right {
        left: 0%;
    }
}

.form-check-label {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 8px;
}

.form-switch {
    padding-left: 2.5em;
    margin-bottom: 16px;
}

.ico-emr {
    font-size: 16px;
}

.hrStyle {
    width: 100%;
    border: 4px solid black;
}

.advice-emr {
    border: 1px solid #ddd;
    padding: 10px;
    margin: 10px;
}

.count-emr {
    padding-left: 20px;
    padding-right: 20px;
}

.emr-cont-header {
    padding: 12px
}

.emr-count-main {
    background-color: #EEF7FF;
    color: #007698;
    padding: 12px;
}

.time-content {
    padding-left: 12px;
}

.time-content-head {
    padding: 16px;
    border-bottom: 1px solid #ddd;
}

.content p {
    margin-bottom: 0;
}

.time-block {
    display: flex;
    align-items: center;
}

.calander-time {
    overflow: scroll;
}

.time-slot-butn {
    margin: 20px;
}

.time-slot-main {
    display: flex;
    align-items: center;
}

.time-slot-butn p {
    text-align: center;
}

.text-time {
    text-decoration: underline;
    cursor: pointer;
}

@media print {
    .printButton {
        display: none !important;
    }

    .ico-emr {
        display: none;
    }

    body {
        font-size: 12px;
    }
}

.prod-manage {
    border: 1px solid #ddd;
}

.prod-item {
    border: 1px solid #ddd;

}

.prod-item img {
    max-width: 100%;
    height: auto;
    /* Maintain aspect ratio */
}

.search-list-item {
    list-style-type: none;
    border: 1px solid #ddd;
    padding: 10px 10px;
    background: white;
}

.list-container {
    padding: 0px;
    position: absolute;
    z-index: 2;
    width: 100%;
}

.mng-prod-img {
    width: 69px;
    height: 69px;
}

.store-select {
    z-index: 2;
    position: relative;
    margin-bottom: 10px;
    margin-top: 14px;
}

.prod-search {
    margin-top: 14px;
    display: flex;
}

.prod-search-input {
    width: 98%;
}

.sync {
    padding: 5px;
}

.mr-1 {
    margin-right: 20px;
}

.dflex {
    display: flex;
    justify-content: space-between;
}

.filters-container {
    flex: 1;
}

.right-table {
    flex: 1;
}


.info-banner {
    padding: 30px 0px;
    background: radial-gradient(circle, rgba(168, 40, 139, 1) 0%, rgba(131, 51, 201, 1) 100%);
}

.info-banner-container p {
    text-align: center;
    margin: 0;
    color: #fff;
    font-weight: 400;
    font-size: 24px;
}

.top-info-container {
    padding: 10px 0px;
    border-bottom: 1px solid #ddd;
}

.top-info-container-mobile {
    padding-top: 10px;
    padding-bottom: 5px;
}

p.subcategory-title {
    color: #333;
    font-size: 16px;
    font-weight: 700;
    text-transform: capitalize;
    margin: 0;
}

.top-info-container label {
    margin-top: 5px;
}

.gridlayout .categories-container {
    display: grid;
    grid-template-columns: auto auto auto;
}

.gridlayout .subcat-item-container {
    border: 1px solid #ddd;
    margin-right: 20px;
    margin-bottom: 20px;
}

.subcategories-list.gridlayout {
    padding: 20px;
}

form.login-form {
    padding: 10px 60px;
}

form.login-form p {
    text-align: center;
    font-size: 16px;
    margin-bottom: 10px;
}

button.btn.btn-next {
    background-color: #3cac47;
    color: #fff;
    width: 100%;
}

.product-item a {
    color: #000;
    text-decoration: none;
}

.user-wallet {
    background-color: #f9f9f9;
    color: #333;
    border-radius: 3px;
    border: 1px solid #eee;
    margin: 16px 16px 40px;
    padding: 16px;
}

.user-wallet__amount {
    color: #000;
    font-size: 32px;
    font-weight: 500;
    margin-top: 4px;
}

.user-wallet__balance {
    color: #666;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    padding: 12px 0 16px;
    text-align: center;
}

.profile-nav__details-block {
    padding: 30px 0;
    text-align: center;
    border-bottom: 1px solid #eee;
}

.profile-nav__details-block .account-profile__img {
    height: 90px;
    width: 90px;
}

.profile-nav__details-block .account-profile__phone {
    font-size: 12px;
    padding-top: 10px;
}

.profile-nav__list {
    line-height: 53px;
    list-style: none;
}

.list-unstyled {
    margin-bottom: 0;
    margin-top: 0;
    list-style: none;
    padding-left: 0;
}

.profile-nav__list-item {
    border-top: 1px solid #eee;
    cursor: pointer;
    font-size: 14px;
    display: list-item;
    color: rgb(102, 102, 102);
    text-decoration: none;
}

.profile-nav__list-item .item-text {
    padding-left: 0 20px;

}

.my-profile__right_rn {
    border-left: 1px solid #eee;
}

.my-profile_rn {
    margin-top: 100px;
    background-color: #fff;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
}

.my-profile_mobile {
    background-color: #fff;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
}

.my-wallet {
    background-color: #fff;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
}

.profile-nav__list-item-active {
    border-top: 1px solid #eee;
    cursor: pointer;
    font-size: 14px;
    display: list-item;
    color: #333;
    background-color: #f9f9f9;
    text-decoration: none;
}

.no-address {
    margin: 0 auto;
    padding-top: 82px;
    text-align: center;

}

.no-address__heading {
    color: #333;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
}

.no-address__sub-heading {
    color: #999;
    font-size: 14px;
    margin-bottom: 30px;
}

.btn--link {
    background: #0c831f;
    border-radius: 3px;
    border: none;
    color: #fff;
    cursor: pointer;
    font-family: inherit;
    font-size: 14px;
    outline: none;
    padding: 10px 10px;
    text-align: center;
    margin-bottom: 10px;
}

.btn--link:hover {
    background: white;
    color: #0c831f;
    border: 1px solid #0c831f;
}

.my-addresses {
    background-color: #fff;
    border: 1px solid #eee;
    border-radius: 3px;
    margin: 26px 30px;
}

.my-addresses__new-address-btn {
    border: none;
    font-size: 13px;
    padding: 16px 20px;
    text-align: left;
    width: 100%;
    color: #0c831f;
    cursor: pointer;
}

.my-addresses__new-address-btn-checkout {
    border: none;
    font-size: 13px;
    padding: 16px 20px;
    text-align: left;
    width: 100%;
    color: #0c831f;
    border: 1px solid #0c831f;
    background-color: #f9f9f9;
}

.add-icon {
    color: #0c831f;
}

.my-addresses__new-address-btn:hover {
    background-color: #f9f9f9;
}

.address-item-container {
    background-color: #fff;
    border-top: 1px solid #eee;
    padding: 16px 20px;
}

.address-item {
    font-size: 14px;
}

.address-item__label {
    color: #333;
    font-weight: 500;
    line-height: normal;
    margin-left: 36px;
}

.address-item__value {
    color: #666;
    line-height: normal;
    margin-left: 36px;
    margin-top: 8px;
}

.text--dark-gray {
    color: #333
}

.Action-address {
    border-bottom: 1px solid black;
    cursor: pointer;
}

.address-title {
    color: rgb(0, 0, 0);
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
}

.addres-subtitle {
    color: rgb(130, 130, 130);
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-top: 10px;
}

.select-control {
    color: rgb(184, 184, 184);
    font-size: 13px;
    font-weight: 500;
    line-height: 17px;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-bottom: 16px;
    border-radius: 8px
}

.form-main {
    margin-top: 24px;
    margin-bottom: 10px;
}

.save-address {
    color: rgb(130, 130, 130);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 9px;
}

.address-1 {
    background: rgb(255, 255, 255);
    padding: 6px 16px;
    border: 1px solid rgb(214, 214, 214);
    border-radius: 8px;
    box-shadow: rgba(28, 28, 28, 0.06) 0px 1px 4px;
    text-align: center;
    color: rgb(28, 28, 28);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    cursor: pointer;
}

.address-2 {
    background: rgb(255, 255, 255);
    padding: 6px 16px;
    border: 1px solid rgb(214, 214, 214);
    border-radius: 8px;
    border: 1px solid rgb(49, 134, 22);
    box-shadow: rgba(28, 28, 28, 0.06) 0px 1px 4px;
    text-align: center;
    color: rgb(28, 28, 28);
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    cursor: pointer;
}

.btn-address {
    padding: 8px 16px;
    background-color: rgb(49, 134, 22);
    color: rgb(255, 255, 255);
    font-size: 17px;
    font-weight: 500;
    line-height: 32px;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
}

.btn-address:hover {

    background-color: white;
    color: rgb(49, 134, 22);
}

.change-location-title {
    font-family: okra;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.non-serviceable-step {
    text-align: center;
    width: 100%;
}

.ns--image {
    width: 168px;
    margin-bottom: 6px;
}

.ns-exclamation {
    color: #333;
    font-size: 18px;
    line-height: 1.4;
    margin-bottom: 4px;
    font-weight: 500;
}

.location__separator {
    color: #333;
    font-size: 14px;
    font-weight: 500;
    height: 19px;
    padding: 16px 12px;
    overflow: hidden;
    text-align: center;
}

.modal-back {
    color: #0c831f;
    margin-left: 0;
    cursor: pointer;
}

.oval-container {
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.oval {
    width: 28px;
    height: 28px;
    border: solid 1px #ccc;
    background-color: #f9f9f9;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 15px;
    position: relative;
}

.oval .separator-text {
    position: relative;
    display: inline-block;
}

.or {
    width: 16px;
    height: 13px;
    font-family: okra;
    font-size: 10px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #bbb;
    text-align: center;
}

.bill-details {
    padding: 0px 16px;
    background-color: rgb(255, 255, 255);
}

.bill-head {
    color: rgb(0, 0, 0);
    margin-bottom: 12px;
    font-size: 15px;
    font-family: Okra-Bold;
    font-weight: 700;
    line-height: 20px;
    background: rgb(255, 255, 255);
    padding-top: 12px
}

.bill-status {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    justify-content: space-between;
    align-items: center;
}

.mrp-head {
    padding: 2px 0px;
    color: rgb(102, 102, 102);
}

.grand-total {
    display: flex;
    margin-top: 12px;
    padding-bottom: 16px;
    justify-content: space-between;
    align-items: center;
    color: rgb(0, 0, 0);
    font-size: 14px;
    font-weight: bold;
    font-family: Okra-Medium;
}

.cuppons-aply {
    color: rgb(130, 130, 130);
    padding: 8px 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    background-color: rgb(252, 252, 252);
    font-weight: 500;
    text-align: center;
}

.CancellationPolicy {
    font-size: 15px;
    line-height: 20px;
    font-family: Okra-bold;
    font-weight: 700;
    padding-top: 12px;
    padding-bottom: 4px;
    color: rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
}

.CancellationPolicy-content {
    font-family: Okra;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: rgb(130, 130, 130);
    background-color: rgb(255, 255, 255);
}

.pac-container {
    z-index: 5000;
}

.add-address-main {
    background-color: rgb(255, 255, 255);
}

.cart-address {
    display: flex;
    align-items: center;
    gap: 20px;
}

.left-arrrow {
    font-size: 24px;
    color: rgb(0, 0, 0);
    font-family: CustomFont;
    cursor: pointer;
}

.left-address {
    font-size: 16px;
    line-height: 20px;
    font-family: Okra;
    font-weight: 700;
    color: rgb(31, 31, 31);
}

.add-address-nav {
    cursor: pointer;
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 16px;
}

.add-icon-nav {
    font-size: 40px;
    color: rgb(12, 131, 31);
}

.address-head-nav {
    color: rgb(0, 0, 0);
    line-height: 20px;
    font-size: 16px;
    font-family: Okra-Regular;
}

.address-p {
    padding: 16px;
    background-color: rgb(238, 238, 238);
    line-height: 16px;
    font-size: 12px;
    color: rgb(153, 153, 153);
    font-family: Okra-Regular;
}

.address-ass {
    margin-bottom: 8px;
    line-height: 20px;
    font-size: 16px;
    font-family: Okra-Bold;
    color: rgb(0, 0, 0);
}

.address-detail {
    line-height: 16px;
    font-size: 15px;
    color: rgb(102, 102, 102);
    font-family: Okra-Regular;
}

.address-main {
    padding: 16px
}

.change-nav {
    cursor: pointer;
    color: rgb(12, 131, 31);
}

.payments-heading {
    color: rgb(28, 28, 28);
    font-size: 24px;
    font-family: Okra-Medium;
    font-weight: 600;
    line-height: 32px;
    padding: 12px 2px;
}

.checkout-left-payment {
    background-color: #fff;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.checkout-cart {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
}

.checkout-step--address {
    padding: 10px;
}

.checkout-step-name {
    font-size: 18px;
    font-weight: 500;
    vertical-align: middle;
}

.selected-address {
    margin-top: 6px;
    color: rgb(153, 153, 153);
    overflow-wrap: break-word;
}

.save-address-ass {
    font-weight: 500 !important;
}

.checkout-card-main {
    display: flex;
    vertical-align: middle;
    align-items: center;
}

.checkot-cart-title {
    font-weight: 100;
}

.checkout-img {
    padding: 0 12px;
    width: 84px;
    height: 57px
}

.checkout-cart-itme-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.button-checkout {
    height: 52px;
    cursor: pointer;
    background-color: rgb(67, 121, 40);
    color: white;
    font-size: 18px;
}

.button-checkout-disbled {
    height: 52px;
    cursor: pointer;
    background-color: rgb(204, 204, 204);
    color: white;
    font-size: 18px;
}

.payment-main {
    margin-top: 100px;
}

.relclass {
    position: relative;
}

.subcategories-list {
    position: sticky;
    top: 100px;
}

.subcategories-list-mobile {
    position: sticky;
    top: 200px;
}

.pad-10 {
    padding-top: 10px;
}

.color-error {
    color: red;
}

@media screen and (max-width: 767.98px) {
    .gridlayout .categories-container {
        display: grid;
        grid-template-columns: auto auto;
    }
}

@media screen and (max-width: 490px) {
    .cat-product {
        width: 80px;
        height: 80px;
        text-align: center;
    }

    .table {
        width: 100%;
    }

    .table th,
    .table td {
        font-size: 12px;
        padding: 5px;
    }

    .apply-new-craft {
        font-size: 12px;
    }
}

.categarios-sub {
    margin-top: 100px;
    background-color: #f9f9f9;
    position: sticky;
    top: 100px;
    transition: transform 0.5s ease-in-out;
    width: 100%;
    z-index: 900;
}

.categarios-sub ul {
    text-decoration: none;
}

.list {
    list-style: none;
    color: #666;
    font-size: 17px;
    margin-left: 17px
}

.cat-sub-main {
    background-color: #fff;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
    position: relative;
}

.select-sub {
    background-color: #f7f7f7;
    width: 100px;
    border: none;
}

.favour {
    text-decoration: line-through;
}

green {
    color: #0c831f;
}

.breadcrumb-item.active {
    color: #0c831f;
}

.breadcrumb {
    display: flex;
    justify-content: center;
}

.breadcrumbs h1 {
    font-size: 3rem;
    text-transform: capitalize;
    margin-top: 0;
    margin-bottom: 3.4rem;
    color: #222222;
    font-weight: 500;
}

.profile-title a {
    color: #000;
    text-decoration: auto;
    text-transform: capitalize;
    font-size: 16px;
}

.filters .filter-title {
    text-decoration: underline;
    font-size: 16px;
}

.filters {
    text-align: left;
}

.crafts-table {
    padding: 15px;
}

.crafts-table .table {
    margin-top: 15px;
}

.filters p {
    padding: 10px 20px;
    margin: 0;
}

.filters a {
    color: #000;
    text-decoration: auto;
}

.filters p:hover,
.filters p.active {
    background: #caf0dc;
    max-width: 100%;
}

.profile-header .dp {
    width: 200px;
    height: 200px;
    border-radius: 50%;
}

.profile-header {
    padding: 20px 60px;
}


.profile-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.profile-info p {
    font-size: 16px;
    margin-bottom: 0px;
    text-transform: capitalize;
}

button.btn.btn-default {
    border: 1px solid #ddd;
}

.followers-info {
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
}

.post-grid .post-item {
    padding: 2px;
}

.my-masonry-grid {
    display: -webkit-box;
    /* Not needed if autoprefixing */
    display: -ms-flexbox;
    /* Not needed if autoprefixing */
    display: flex;
    width: auto;
}

.my-masonry-grid_column {
    background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column>div {
    /* change div to reference your elements you put in <Masonry> */
    background: white;
    margin-bottom: 0px;
}

.my-masonry-grid .post-img {
    width: 100%;
    max-height: none;
}

.display-bio {
    padding: 20px 40px;
}

.projects-container {
    padding: 20px 60px;
}

.crafts-grid {
    display: grid;
    grid-template-columns: auto auto auto;
}

.craft-item {
    padding: 20px;
    border: 1px solid #ddd;
    margin: 10px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
}

.craft-item:hover {
    background: #c000b1;
    color: #fff;
}

.craft-item p {
    margin-bottom: 0px;
}

.form-render {
    padding: 30px 60px;
}

.menu-container {
    width: 100px;
}

.arrowicon {
    text-align: left;
}

.search-screen-container {
    display: flex;
}

.filters-container {
    max-width: 300px;
    padding: 0px 15px;
    margin-bottom: 20px;
    margin-top: 75px
}

.filters-container button {
    width: 100%;
}

.profile-tablinks {
    /* max-width: 650px; */
    margin-left: auto;
    margin-right: auto;
}

.caption-container {
    display: flex;
    place-content: space-between;
    border: 1px solid #ddd;
    padding: 5px;
}

.tab-content {
    border-top: 1px solid #ddd;
}

ul.nav.nav-tabs .nav-link,
ul.nav.custom-nav-tabs .nav-link {
    color: #666;
}

ul.nav.custom-nav-tabs {
    justify-content: space-between;
}

ul.nav.custom-nav-tabs .nav-link.active,
ul.nav.nav-tabs .nav-link.active {
    border: none;
    border-bottom: 5px solid #f03697;
}

.profile-tablinks a {
    color: #666;
    text-decoration: auto;
}

.profile-tablinks .active p {
    border-bottom: 2px solid #f03697;
}

.profile-tablinks p {
    margin: 0;
    text-transform: uppercase;
    font-size: 16px;
    padding-bottom: 10px;
}

.about-container {
    border-top: 1px solid #ddd;
}

.editabledp {
    position: relative;
    width: 50%;
}

.dp {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
}

.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.editabledp:hover .dp {
    opacity: 0.3;
}

.editabledp:hover .middle {
    opacity: 1;
}

.text {
    color: white;
    font-size: 16px;
    padding: 7px;
    background: #ed0e82;
    border-radius: 5px;
}

.edit-profile-container {
    text-align: center;
}

.edit-profile-container img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 20px;
}

.display-bio .form-select:disabled {
    background: #e9ecef;
}

.display-bio .css-16xfy0z-control {
    background: #e9ecef;
}

.display-bio .css-16xfy0z-control .css-1xc3v61-indicatorContainer {
    display: none;
}

.zoomaction {
    text-align: center;
}

.zoomaction input {
    height: 10px;
    margin-left: 10px;
}

.no-profile {
    text-align: center;
    margin-bottom: 60px;
}

.options-container-mobile {
    border-top: 1px solid #dbdbdb;
}

.top-search-icon {
    color: #000;
}

.show-only-mobile {
    display: none;
}

@media screen and (max-width: 767.98px) {
    .homescreen .post-image {
        margin-left: -15px;
        margin-right: -15px;
    }

    .profile-header {
        padding: 10px 15px;
    }

    .profile-header .dp {
        width: 100px;
        height: 100px;
    }

    .editabledp {
        width: 100%;
    }

    .profile-info {
        display: block;
    }

    .profile-info .btn {
        margin-right: 10px;
    }

    .show-only-mobile {
        display: block;
    }

    .show-only-desktop {
        display: none;
    }

    .model-titles {
        display: flex;
        margin-bottom: 10px;
        align-items: center;
        justify-content: space-between;
    }

    .crafts-grid {
        grid-template-columns: auto auto;
    }

    .customtable-responsive {
        max-width: calc(100vw - 50px);
        overflow: auto;
    }

    .projects-container {
        padding: 10px 0px;
    }

}

.Audi-require {
    color: #ed0d81;
    text-decoration: underline;
    text-align: right;
    margin-bottom: 20px;
}

.contact-detile {
    display: flex;
    gap: 39;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px;
    font-weight: 500;
}

.data-text {
    margin-left: 10px;
    font-weight: 400;
}

a.data-text {
    color: #212529;
    text-decoration: none;
}

.client-options {
    height: 24px;
    width: 24px
}

.fade.modal-size-craft.modal.show {
    padding: 0 !important;
}

.modal.fade .modal-dialog {
    border-radius: 0;
}

.modal-size-craft .modal-dialog {
    margin: 0;
    max-width: 100%;
    width: 100%;
}

.report-filters {
    display: flex;
    gap: 16px;
    margin: 16px 0 16px 20px;
    min-height: 37px;
}

.reports-parent #react-select-2-listbox,
.reports-parent #react-select-3-listbox,
.reports-parent #react-select-4-listbox,
.reports-parent #react-select-5-listbox,
.report-filters #react-select-2-listbox {
    position: absolute;
    top: 30px;
    z-index: 99;
}

.card-style {
    cursor: pointer;
    overflow: visible;
    position: relative;
    border-radius: 16px;
    /* color: #fff; */
    display: flex;
    margin-top: 40px;
}

.card-types {
    margin-left: auto;
    margin-right: auto;
    margin-top: -15px;
    max-width: 200px;
    background: #7a7acc;
    border-radius: 5px;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 0;
    padding: 0 10px;
    position: relative;
}

.audi-edit {
    color: #000;
    position: absolute;
    right: 12px;
    top: 0;
}

h4.banner-title {
    color: rgb(0, 0, 0);
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 18px;
    line-height: 30px;
    text-transform: capitalize;
    text-align: center;
    font-weight: 700;
}

.card-bottom-inner,
.card-descrt {
    display: flex;
    justify-content: space-between;
}

.card-descrt {
    background-color: #fff;
    border-radius: 16px;
    padding: 20px 0 0;
    flex-direction: column;
}

.card-descrt-inner {
    height: 290px;
    padding: 0 20px 10px;
}

.project_title {
    color: #000;
    align-items: center;
    display: flex;
    gap: 12px;
    margin-bottom: 10px;
}

.audi-id {
    background-color: #fff;
    border: 1px solid #bcbaba;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, .1);
    font-weight: 600;
    padding: 10px;
}

.Audi-project-title {
    color: #1c1c1c;
    font-size: 14px;
    font-weight: 600;
    opacity: 1;
}

.Audi-description {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    color: #676767;
    display: -webkit-box;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    opacity: 1;
    overflow: hidden;
}

.card-audi {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.audi-label {
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
    color: #fff;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    padding: 5px 10px;
}

.card-detail {
    border-bottom: 1px solid #e5e5e5;
    border-top: 1px solid #e5e5e5;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.card-botton-centent {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 0;
    justify-content: center;
    font-weight: 800;
}

.card-cont-title {
    color: #333;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 1.4;
    text-align: left;
}

.card-cont-detail {
    color: #555;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.6;
    /* text-align: center; */
}

.auditionList-require {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: wrap;
    height: 90px;
}

.card-bottom {
    background: #fff;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    border-top: 1px solid #ddd;
    color: #000;
    padding: 5px;
}

.card-bottom-inner {
    align-items: center;
}

.card-bottom-lefts {
    align-items: center;
    display: flex;
    gap: 10px;
}

.card-bottom-right {
    display: flex;
}

.audi-edit .dropdown .btn.btn-success {
    background: none;
    border: none;
    color: #000;
}

.dropdown-toggle::after {
    content: none;
}

.user-Actions {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.view-btn {
    background: #6a0dad;
    color: #fff;
    font-weight: 500;
    /* margin-bottom: 8px; */
}

.message-btn {
    align-items: center;
    border: 1px solid #edd3ff;
    color: #212529;
    display: flex;
    font-weight: 400;
    gap: 10px;
    height: 31px;
}

.view-btn,
.message-btn {
    border-radius: 18px;
    font-size: 18px;
    letter-spacing: .02em;
    line-height: 27px;
    padding: 5px 15px;
    text-align: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.marginTop .dexCwo {
    scrollbar-width: none;
}

.Copy-icon {
    width: 25px;
    height: 25px;
}

.Copy-icon:active {
    color: #6a0dad;
}